.asset-group-wrapper {
  border-radius: 16px;
  border: 1px solid var(--button-border);
  background: #f4efec;
  padding: 10px;
}

.icon-button-wrapper {
  right: 8px;
  top: 8px;
  border-radius: 16px;
}
