.dialog-title {
  position: relative;
  padding-right: 48px;
}

.close-button {
  position: absolute;
  right: 8px;
  top: 8px;
}
