.user-message-container {
  padding-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-message {
  border-radius: 16px;
  background: white;
  padding: 4px 16px;
  word-break: break-word;
}
