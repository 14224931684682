.wrapper {
  border-radius: 16px;
  border: 1px solid rgb(228 223 212 / 50%);
  background: #fff;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: fit-content;
}
