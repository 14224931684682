.base {
  cursor: pointer;
  position: absolute;
  left: calc(50% - 68px);
  z-index: 1000;
  border-radius: 16px;
  border: 1px dashed var(--primary-color);
  backdrop-filter: blur(35px);
  width: 128px;
}

.has-entities {
  border-radius: 16px;
  border: 1px solid var(--input-output-border);
  background: var(--button-secondary-border);
}

.has-entities:hover {
  background: var(--action-focus);
  backdrop-filter: blur(35px);
}

.no-entities {
  backdrop-filter: blur(35px);
}

.no-entities:hover {
  background: var(--divider-light);
}

.icon {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.no-entities-icon {
  background-color: var(--white);
}

.opened {
  border: 2px solid var(--primary-color);
}

.top {
  top: 24px;
}

.bottom {
  bottom: 24px;
}
