.account-image {
  width: 24px;
  height: 24px;
}

.account-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.grid-area-control {
  grid-area: control;
}

.grid-area-label {
  color: rgb(26 26 26);
  font-weight: 450;
  grid-area: label;
  line-height: 1.5;
}
