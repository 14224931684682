.text-wrap {
  text-wrap: wrap;
}

.text-d-none {
  text-decoration: none;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.capitalize {
  text-transform: capitalize;
}

.capitalize-first-letter {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.text-transform-none {
  text-transform: none;
}

.line-h-24px {
  line-height: 24px;
}

.break-words {
  white-space: normal;
  word-break: break-word;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.font-size-12px {
  font-size: 12px;
}
