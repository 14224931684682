.border-radius-4px {
  border-radius: 4px;
}

.border-radius-16px {
  border-radius: 16px;
}

.border-radius-32px {
  border-radius: 32px;
}

.border-radius-bottom-16px {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.border-radius-24px {
  border-radius: 24px;
}

.no-border {
  border: none;
}

.border-radius-50 {
  border-radius: 50%;
}

.border-radius-top-4px {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-radius-top-8px {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.border-radius-top-12px {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.border-radius-top-16px {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.border-radius-top-24px {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.popover-paper-borders {
  border-radius: 16px;
  box-shadow: 0 6px 8px 0 rgb(101 91 71 / 6%);
  border: 1px solid #d3cec3;
}

.primary-button-border {
  border: 1px solid var(--primary-button-border-color);
}

.light-chat-border {
  border: 1px solid var(--light-chat-border);
}

.chat-border {
  border: 1px solid var(--chat-border);
}
