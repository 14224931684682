.selected-file-paper {
  border-radius: 16px;
  border: 1px solid var(--button-border);
  background: var(--right-side-content-background);
  display: flex;
  padding: 16px;
  gap: 16px;
}

.linear-progress {
  border-radius: 100px;
  height: 6px;
}
