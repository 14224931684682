.p-0px {
  padding: 0;
}

.p-0px-imp {
  padding: 0 !important;
}

.p-2px {
  padding: 2px;
}

.p-4px {
  padding: 4px;
}

.p-8px {
  padding: 8px;
}

.p-16px {
  padding: 16px;
}

.p-24px {
  padding: 24px;
}

.p-32px {
  padding: 32px;
}

.p-b-0px {
  padding-bottom: 0;
}

.p-b-8px {
  padding-bottom: 8px;
}

.p-b-16px {
  padding-bottom: 16px;
}

.p-b-20px {
  padding-bottom: 20px;
}

.p-b-24px {
  padding-bottom: 24px;
}

.p-b-32px {
  padding-bottom: 32px;
}

.p-h-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.p-h-8px {
  padding-left: 8px;
  padding-right: 8px;
}

.p-h-12px {
  padding-left: 12px;
  padding-right: 12px;
}

.p-h-16px {
  padding-left: 16px;
  padding-right: 16px;
}

.p-h-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.p-h-24px {
  padding-left: 24px;
  padding-right: 24px;
}

.p-h-32px {
  padding-left: 32px;
  padding-right: 32px;
}

.p-h-40px {
  padding-left: 40px;
  padding-right: 40px;
}

.p-l-0px {
  padding-left: 0;
}

.p-l-8px {
  padding-left: 8px;
}

.p-l-16px {
  padding-left: 16px;
}

.p-l-32px {
  padding-left: 32px;
}

.p-r-0px {
  padding-right: 0;
}

.p-r-2px {
  padding-right: 2px;
}

.p-r-8px {
  padding-right: 8px;
}

.p-r-16px {
  padding-right: 16px;
}

.p-r-24px {
  padding-right: 24px;
}

.p-r-70px {
  padding-right: 70px;
}

.p-t-0px {
  padding-top: 0;
}

.p-t-2px {
  padding-top: 2px;
}

.p-t-4px {
  padding-top: 4px;
}

.p-t-6px {
  padding-top: 6px;
}

.p-t-8px {
  padding-top: 8px;
}

.p-t-10px {
  padding-top: 10px;
}

.p-t-12px {
  padding-top: 12px;
}

.p-t-16px {
  padding-top: 16px;
}

.p-t-24px {
  padding-top: 24px;
}

.p-t-32px {
  padding-top: 32px;
}

.p-t-40px {
  padding-top: 40px;
}

.p-v-0px {
  padding-top: 0;
  padding-bottom: 0;
}

.p-v-2px {
  padding-top: 2px;
  padding-bottom: 2px;
}

.p-v-4px {
  padding-top: 4px;
  padding-bottom: 4px;
}

.p-v-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

.p-v-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.p-v-16px {
  padding-top: 16px;
  padding-bottom: 16px;
}

.p-v-24px {
  padding-top: 24px;
  padding-bottom: 24px;
}

.p-v-32px {
  padding-top: 32px;
  padding-bottom: 32px;
}
