/* Placeholder (on every new line) */
/* stylelint-disable selector-class-pattern */
.ProseMirror p.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
/* stylelint-enable selector-class-pattern */

.tiptap-textfield-p {
  padding: 0 24px !important;
}

/* stylelint-disable-next-line */
.ProseMirror .negative-horizontal-margin-8 {
  margin-left: -8px;
  margin-right: -8px;
}

.disable-tiptap-hover > :last-child,
.disable-tiptap-hover > :nth-last-child(2) {
  border-color: rgb(0 0 0 / 12%) !important;
  border-width: 1px !important;
}

.updated-tiptap-hover > :last-child,
.updated-tiptap-hover > :nth-last-child(2) {
  border-color: rgb(0 0 0 / 12%) !important;
  border-width: 1px !important;
}

.updated-tiptap-hover:hover > :last-child,
.updated-tiptap-hover:hover > :nth-last-child(2) {
  border-color: rgb(0 0 0 / 87%) !important;
  border-width: 1px !important;
}

/* stylelint-disable selector-class-pattern */
.updated-tiptap-hover.MuiTiptap-FieldContainer-focused > :last-child,
.updated-tiptap-hover.MuiTiptap-FieldContainer-focused > :nth-last-child(2) {
  border-color: #8a2dff !important;
  border-width: 2px !important;
}
/* stylelint-enable selector-class-pattern */

/* add padding, except for bullets and checklists, otherwise looks cramped in markdown */
/* stylelint-disable selector-class-pattern */
.tiptap p:not(.MuiTypography-root) {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.75 !important;
}

.tiptap h1:not(.MuiTypography-root),
.tiptap h2:not(.MuiTypography-root),
.tiptap h3:not(.MuiTypography-root),
.tiptap h4:not(.MuiTypography-root),
.tiptap h5:not(.MuiTypography-root),
.tiptap h6:not(.MuiTypography-root),
.tiptap p:not(.MuiTypography-root) {
  padding-block: 4px !important;
}

.tiptap p:not(.MuiTypography-root, li > p, li[data-checked] p) {
  padding-block: 8px !important;
}

.tiptap h1:not(.MuiTypography-root) {
  font-family: Konnect, sans-serif !important;
  font-size: 2.25rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

.tiptap h2:not(.MuiTypography-root) {
  font-family: Konnect, sans-serif !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  line-height: 1.33 !important;

  /* font-weight: 300 !important; */
}

.tiptap h3:not(.MuiTypography-root) {
  font-family: Konnect, sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
}

.tiptap h4:not(.MuiTypography-root) {
  font-family: Konnect, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.75 !important;
}

.tiptap h5:not(.MuiTypography-root) {
  font-family: Konnect, sans-serif !important;
  font-size: 1rem !important;
}

.tiptap h6:not(.MuiTypography-root) {
  font-family: Konnect, sans-serif !important;
  font-size: 0.8rem !important;
}

/* stylelint-enable selector-class-pattern */

.default-tiptap-textfield-p {
  padding: 0 !important;
}

.z-index-max {
  z-index: 1300;
}
