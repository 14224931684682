.option-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.option-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  flex-shrink: 0;
}

.menu-item {
  padding-right: 8px;
}
