.object-key {
  border-radius: 16px;
  background-color: var(--chip-purple-background);
  color: var(--primary-color) !important;
  padding: 0 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 22.75px;
  display: inline-block;
  margin-left: 4px;
}

.string-value {
  word-break: break-word;
}

.copy-icon {
  display: flex !important;
}

.copy-to-clipboard-container {
  vertical-align: middle !important;
}
