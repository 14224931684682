.integration-card {
  max-width: 495px;
  min-width: 345px;
  width: 345px;
  height: 152px;
  padding: 5px;
  background-color: #fff;
  border-radius: 16px;
  color: #121729de;
  border: 1px solid var(--button-border);
  box-shadow: none;
  margin: 8px;
}

.integration-card-header {
  padding-bottom: 0;
}
