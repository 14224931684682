.entity-card {
  max-width: 495px;
  min-width: 345px;
  width: 345px;
  padding: 5px;
  border-radius: 16px;
  color: #121729de;
  border: 1px solid var(--button-border);
  box-shadow: none;
}

.standard-entity-card {
  border: 1px solid var(--button-border);
  background-color: #fff;
  height: 146px;
}

.add-entity-card {
  border: 2px dashed rgb(138 45 255);
}

.entity-card.entity-card-disabled-actions {
  height: 156px;
}

.entity-card:hover {
  cursor: pointer;
  box-shadow: 0 6px 8px 0 rgb(101 91 71 / 6%);
}

.entity-card-header {
  padding-bottom: 0;
}
