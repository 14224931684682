.divider {
  border-radius: 1px;
  background: #e4dfd4;
  width: 100%;
  height: 1px;
  margin: 10px 0;
  min-height: 1px;
}

.paper {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1000;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 6%);
  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-height: calc(100vh - 116px);
}
