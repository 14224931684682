.tags-list-container {
  padding: 12px 8px 12px 12px;
  border: 1px solid rgb(18 23 41 / 20%);
  border-radius: 16px;
  max-height: 350px;
  overflow-y: scroll;
}

.tags-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 8px;
}

.tags-list-item:hover {
  background-color: var(--background-color);
  border-radius: 16px;
}

.tag-delete-button {
  cursor: pointer;
  height: 22px;
  width: 22px;
  color: var(--toolflow-text-disabled-color);
}
