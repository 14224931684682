.transition-rotate {
  transition: rotate 0.3s ease;
  rotate: 180deg;
}

.transition-rotate[data-expanded="true"] {
  rotate: 0deg;
}

.transition-grid {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 300ms ease;
  overflow: hidden;
}

.transition-grid[data-expanded="true"] {
  grid-template-rows: 1fr;
  overflow: visible;
}
