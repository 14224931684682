.dropzone-style {
  border-radius: 16px;
  border: 1px dashed var(--button-border);
  background: var(--right-side-content-background);
  display: flex;
  width: 100%;
  padding: 16px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
}
