/* 
===============================================
SORA FONTS
===============================================
*/

@font-face {
  font-family: Konnect;
  font-weight: 300;
  src:
    local("KonnectLight"),
    url("../../globalTheme/font/Konnect-Light.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 400;
  src:
    local("KonnectRegular"),
    url("../../globalTheme/font/Konnect-Regular.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 500;
  src:
    local("KonnectMedium"),
    url("../../globalTheme/font/Konnect-Medium.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 600;
  src:
    local("KonnectSemiBold"),
    url("../../globalTheme/font/Konnect-SemiBold.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 700;
  src:
    local("KonnectBold"),
    url("../../globalTheme/font/Konnect-Bold.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 800;
  src:
    local("KonnectExtraBold"),
    url("../../globalTheme/font/Konnect-ExtraBold.woff") format("woff");
}

/* 
  ===============================================
  KONNECT FONTS
  ===============================================
  */
@font-face {
  font-family: Konnect;
  font-weight: 300;
  src:
    local("KonnectLight"),
    url("../../globalTheme/font/Konnect-Light.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 400;
  src:
    local("KonnectRegular"),
    url("../../globalTheme/font/Konnect-Regular.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 500;
  src:
    local("KonnectMedium"),
    url("../../globalTheme/font/Konnect-Medium.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 600;
  src:
    local("KonnectSemiBold"),
    url("../../globalTheme/font/Konnect-SemiBold.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 700;
  src:
    local("KonnectBold"),
    url("../../globalTheme/font/Konnect-Bold.woff") format("woff");
}

@font-face {
  font-family: Konnect;
  font-weight: 800;
  src:
    local("KonnectExtraBold"),
    url("../../globalTheme/font/Konnect-ExtraBold.woff") format("woff");
}
