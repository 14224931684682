.container {
  position: relative;
}

.select-field {
  width: 120px;
}

.select-field div:first-child,
.select-field-item {
  font-size: 14px;
  padding-right: 0 !important;
}

.select-field-icon {
  position: absolute;
  right: 0;
  top: 2;
  pointer-events: none;
  color: rgb(0 0 0 / 23%);
}

.select-field::before {
  border: none !important;
}
