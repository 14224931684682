@import url("./styles/positioning.css");
@import url("./styles/fonts.css");
@import url("./styles/heights.css");
@import url("./styles/margins.css");
@import url("./styles/overflow.css");
@import url("./styles/padding.css");
@import url("./styles/text.css");
@import url("./styles/tipTap.css");
@import url("./styles/widths.css");
@import url("./styles/colors.css");
@import url("./styles/borders.css");
@import url("./styles/cursors.css");
@import url("./styles/hover.css");
@import url("./styles/buttons.css");
@import url("./muiUtils/muiStyles.css");
@import url("./featureStyles/toolbuilder.css");
@import url("./styles/react-json.css");
@import url("./styles/transitions.css");

:root {
  --font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen,
    ubuntu, cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: Konnect, var(--font-family), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
