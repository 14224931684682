.pos-relative {
  position: relative;
}

.pos-fixed {
  position: fixed;
}

.pos-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.right-neg-8px {
  right: -8px;
}

.top-16px {
  top: 8px;
}

.top-24px {
  top: 24px;
}

.right-24px {
  right: 24px;
}

.left-16px {
  left: 8px;
}

.left-24px {
  left: 24px;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.inline {
  display: inline;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-i-center {
  align-items: center;
}

.align-i-flex-start {
  align-items: flex-start;
}

.align-i-flex-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.justify-center {
  justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.z-1000 {
  z-index: 1000;
}
