.min-w-unset {
  min-width: unset;
}

.min-w-42px {
  min-width: 42px;
}

.min-w-180px {
  min-width: 180px;
}

.min-w-300px {
  min-width: 300px;
}

.min-w-100-percent {
  min-width: 100%;
}

.max-w-350px {
  max-width: 350px;
}

.max-w-540px {
  max-width: 540px;
}

.max-w-640px {
  max-width: 640px;
}

.max-w-40-percent {
  max-width: 40%;
}

.max-w-50-percent {
  max-width: 50%;
}

.max-w-55-percent {
  max-width: 55%;
}

.max-w-70-percent {
  max-width: 70%;
}

.max-w-100-percent {
  max-width: 100%;
}

.max-w-140px {
  max-width: 140px;
}

.max-w-600px {
  max-width: 600px;
}

.max-w-1200px {
  max-width: 1200px;
}

.w-64px {
  width: 64px;
}

.w-300px {
  width: 300px;
}

.w-100-percent {
  width: 100%;
}

.w-fit-content {
  width: fit-content;
}

.w-fit-content-important {
  width: fit-content !important;
}

.width-inherit {
  width: inherit;
}

.width-auto {
  width: auto;
}
